/* bce784
5dd39e
348aa7
525174
513b56 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
}

.full-height {
  height: 100vh;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  font-size: 62.5%;
  margin: 0;
  padding: 0px 0px 4px 0px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131415;
  color: #221c34;
}

@media only screen and (max-width: 1023px) {
  body {
    font-size: 64%;
  }
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 31%;
    padding-bottom: 16px;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: rgba(255, 255, 255, 0.5);
}

a:link,
a:visited,
a:active {
  color: #221c34;
}
