.MCQ {
  text-align: center;
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
  font-size: 2.5em;
}

h2 {
  margin-bottom: 10px;
  font-size: 2em;
}

.chapter-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chapter-selection button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  background-color: #3b56cc;
  color: white;
  cursor: pointer;
  width: 80%;
  max-width: 300px;
  transition: background-color 0.3s;
}

.chapter-selection button:hover {
  background-color: #45a049;
}

.mcq-test {
  text-align: left;
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.mcq-test h2 {
  font-size: 1.8em;
}

.option {
  margin: 10px 0;
}

.option label {
  margin-left: 10px;
  font-size: 1.4em; /* Increased font size for better readability */
}

button {
  margin-top: 20px;
  padding: 20px 40px; /* Increased padding for better mobile experience */
  font-size: 1.4em; /* Increased font size */
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #45a049;
}

/* Media queries for responsive design */
@media (max-width: 600px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  .chapter-selection button {
    font-size: 3em;
    padding: 10px;
  }

  .mcq-test {
    padding: 10px;
  }

  .option label {
    font-size: 3em; /* Adjusted font size for mobile */
  }

  button {
    font-size: 4em;
    width: 100%;
    padding: 20px 40px; /* Increased padding for mobile */
  }
}
